import { Button } from '@/components/ui/button';
import { CBPayInstanceType, initOnRamp } from '@coinbase/cbpay-js';
import { useState } from 'react';
import { useAccount } from 'wagmi';
import Spinner from '../Spinner';
const CoinbasePay = () => {
  const [onrampInstance, setOnrampInstance] = useState<CBPayInstanceType | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    address
  } = useAccount();
  const handleClick = () => {
    setLoading(true);
    if (address && !onrampInstance) {
      initOnRamp({
        appId: process.env.NEXT_PUBLIC_CB_PAY as string,
        widgetParameters: {
          destinationWallets: [{
            // Destination address where the purchased tokens will be sent.
            address: address,
            blockchains: ['base']
          }]
        },
        onSuccess: () => {
          console.log('success');
          setLoading(false);
        },
        onExit: error => {
          console.log('exit', error);
          setLoading(false);
        },
        onEvent: event => {
          console.log('event', event);
        },
        experienceLoggedIn: 'embedded',
        experienceLoggedOut: 'popup',
        closeOnExit: true,
        closeOnSuccess: true
      }, (_, instance) => {
        setLoading(false);
        setOnrampInstance(instance as CBPayInstanceType);
        instance?.open();
      });
    } else {
      onrampInstance?.open();
      setLoading(false);
    }
  };
  return <Button variant="oneCT" className="w-full" onClick={handleClick} data-sentry-element="Button" data-sentry-component="CoinbasePay" data-sentry-source-file="CoinbasePay.tsx">
      {loading ? <div className="flex items-center gap-2">
          <Spinner />
          <p>Coinbase Pay</p>
        </div> : 'Coinbase Pay'}
    </Button>;
};
export default CoinbasePay;