import { useAccount, useSwitchChain } from 'wagmi';

const useNetworkSwitch = () => {
  const { isConnected, chain } = useAccount();
  const { chains, switchChain } = useSwitchChain();

  const handleSwitchChain = () => {
    switchChain({ chainId: chains[0].id as any });
  };

  const unsupported = isConnected && !chain;

  return { unsupported, switchToBaseChain: handleSwitchChain };
};

export default useNetworkSwitch;
