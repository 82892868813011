import { contractsConfig } from '@/blockchain/contracts';

import { Hash, hexToString, zeroHash } from 'viem';
import { useAccount, useReadContract, useReadContracts } from 'wagmi';

const useReferral = () => {
  const { address } = useAccount();

  const { data: referralData, refetch: refetchReferralData } = useReadContracts({
    contracts: [
      {
        ...contractsConfig.TradingStorage,
        functionName: 'rebates',
        args: [address!],
      },
      {
        ...contractsConfig.Referral,
        functionName: 'referrerTiers',
        args: [address],
      },
      {
        ...contractsConfig.Referral,
        functionName: 'codes',
        args: [address],
      },
    ],
    query: {
      enabled: !!address,
    },
  });

  const earnings = (referralData?.[0].result as bigint) || 0n;
  const tier = (referralData?.[1].result as bigint) || 0n;
  const _referralCode = (referralData?.[2].result as Hash) || '';
  const referralCode = _referralCode === zeroHash ? '' : hexToString(_referralCode, { size: 32 });

  const { data: rebatePctResult } = useReadContract({
    ...contractsConfig.Referral,
    functionName: 'tiers',
    args: [tier],
    query: {
      enabled: !!address && !!referralData,
      refetchInterval: 30_000,
    },
  });

  const refRebatePct = rebatePctResult ? (rebatePctResult as any)[1] : 0;

  // trader

  const { data: traderCodeResult, refetch: refetchTraderData } = useReadContract({
    ...contractsConfig.Referral,
    functionName: 'traderReferralCodes',
    args: [address],
    query: {
      enabled: !!address,
      refetchInterval: 30_000,
    },
  });

  const _traderCode = (traderCodeResult as Hash) || '';
  const traderCode = _traderCode === zeroHash ? '' : hexToString(_traderCode, { size: 32 });

  const { data: traderReferralInfo } = useReadContract({
    ...contractsConfig.Referral,
    functionName: 'getTraderReferralInfo',
    args: [address],
    query: {
      enabled: !!address && !!traderCodeResult && !!traderCode,
    },
  });

  const { data: traderReferrerTier } = useReadContract({
    ...contractsConfig.Referral,
    functionName: 'referrerTiers',
    args: [(traderReferralInfo as any)?.[1] || ''],
    query: {
      enabled:
        !!address &&
        !!traderCodeResult &&
        !!traderCode &&
        traderReferralInfo &&
        (traderReferralInfo as any).length,
    },
  });

  const { data: traderTiers } = useReadContract({
    ...contractsConfig.Referral,
    functionName: 'tiers',
    args: [traderReferrerTier || 0],
    query: {
      enabled: !!address && !!traderCode,
    },
  });

  const feeDiscountPct = traderTiers ? Number((traderTiers as any)[0]) / 100 : 0;

  return {
    earnings,
    tier,
    traderCode,
    traderReferrerTier,
    referralCode,
    feeDiscountPct,
    refRebatePct,
    refetchReferralData,
    refetchTraderData,
  };
};

export default useReferral;
