import { Switch } from '@/components/ui/switch';
import useWalletBalances from '@/hooks/shared/useWalletBalance';
import { formatAddress } from '@/lib/utils';
import { Copy } from 'lucide-react';
import { HiLightningBolt } from 'react-icons/hi';
import { useCopyToClipboard } from 'usehooks-ts';
import { Address } from 'viem';
import { useState } from 'react';
import { useOneCTStore } from '@/store/oneCT';
import useOneCT from '@/hooks/shared/useOneCT';
import { Button } from '@/components/ui/button';
import Image from 'next/image';
import useAppToast from '@/hooks/shared/useAppToast';
import ManageFunds from '../oneCT/manageFunds/ManageFunds';
import { Skeleton } from '@/components/ui/skeleton';
import PinModal from '../oneCT/PinModal';
const OneCTSection = ({
  wallet
}: {
  wallet: {
    address: Address;
    isEnabled: boolean;
  };
}) => {
  const {
    privateKeyCopied,
    copied
  } = useAppToast();
  const {
    walletBalanceByAddress
  } = useWalletBalances();
  const [, copy] = useCopyToClipboard();
  const [openPinModal, setOpenPinModal] = useState<boolean>(false);
  const {
    updateOneCTStore,
    setEnableOneCT,
    disableOneCT,
    privateKey,
    oneCTStorage
  } = useOneCTStore(state => state);
  const {
    gasPerTradeEst,
    ethPerTradeEstLoading
  } = useOneCT();
  const {
    currentWalletBalance,
    isLoading,
    currentWallet
  } = useWalletBalances();
  const estNumberOfTrades = Math.floor(Number(currentWalletBalance?.eth?.formatted) / Number(gasPerTradeEst?.ethPerTradeEst));
  const handleCopyAddress = () => {
    copy(wallet.address);
    copied(`${formatAddress(wallet.address)} copied to clipboard`);
  };
  return <div className="gradient-violet rounded-lg p-4 flex flex-col gap-2" data-sentry-component="OneCTSection" data-sentry-source-file="OneCTSection.tsx">
      <p className="font-fono font-normal text-sm">One-Click Trading</p>
      <div className="flex flex-col gap-3">
        <div className="bg-grey-800 rounded-lg p-2 flex gap-2">
          <HiLightningBolt className="text-primary size-6" data-sentry-element="HiLightningBolt" data-sentry-source-file="OneCTSection.tsx" data-sentry-element="HiLightningBolt" />
          <div className="flex flex-col w-full gap-1">
            <div className="flex justify-between">
              <div className="flex gap-1 items-center">
                <span className="text-grey-100 font-normal font-fono text-sm md:text-base">
                  {formatAddress(wallet.address)}
                </span>
                <Copy className="size-4 text-grey-100 cursor-pointer ml-2" onClick={() => handleCopyAddress()} data-sentry-element="Copy" data-sentry-source-file="OneCTSection.tsx" data-sentry-element="Copy" />
              </div>
              <div>
                <Switch checked={currentWallet === wallet.address} onClick={async () => {
                updateOneCTStore('pinError', false);
                const current1CT = oneCTStorage[wallet.address];
                if (wallet.isEnabled) {
                  disableOneCT(wallet.address);
                } else if (current1CT) {
                  setEnableOneCT(current1CT.publicKey as Address);
                } else {
                  setOpenPinModal(true);
                }
              }} data-sentry-element="Switch" data-sentry-source-file="OneCTSection.tsx" data-sentry-element="Switch" />
              </div>

              <PinModal openPinModal={openPinModal} setOpenPinModal={setOpenPinModal} data-sentry-element="PinModal" data-sentry-source-file="OneCTSection.tsx" data-sentry-element="PinModal" />
            </div>

            {currentWallet !== wallet.address && <>
                <div className="flex justify-between">
                  <p className="text-grey-300-disabled-text font-normal text-sm">Trading Balance</p>
                  <p className="text-grey-300-disabled-text font-medium text-sm">
                    {walletBalanceByAddress[wallet.address]?.usdc?.formatted} USD
                  </p>
                </div>

                <div className="flex justify-between">
                  <p className="text-grey-300-disabled-text font-normal text-sm">Gas Balance</p>
                  <p className="text-grey-300-disabled-text font-medium text-sm">
                    {walletBalanceByAddress[wallet.address]?.eth?.formatted} ETH
                  </p>
                </div>
              </>}
          </div>
        </div>

        {currentWallet === wallet.address && <>
            <div className="p-2">
              <div className="flex justify-between">
                <div className="flex flex-col gap-1">
                  <p className="text-grey-100 text-sm font-normal">Gas Balance</p>
                  <div className="flex gap-1">
                    <Image src="/images/pairs/crypto/ETH.svg" alt="eth" width={20} height={20} />
                    <div className="inline-flex gap-1.5">
                      <p className="font-fono text-grey-025 text-base font-normal">
                        {currentWalletBalance?.eth.formatted} ETH{' '}
                      </p>
                      {ethPerTradeEstLoading || isLoading ? <div className="flex gap-1 items-center">
                          <p className="font-fono text-secondary-200 text-sm font-normal">~ </p>
                          <Skeleton className="w-16 h-6" />
                        </div> : <p className="font-fono text-secondary-200 text-sm font-normal">
                          ~ {estNumberOfTrades} Txns
                        </p>}
                    </div>
                  </div>
                </div>

                <div className="flex gap-2">
                  <ManageFunds isAdd={true} asset="ETH" />
                  <ManageFunds isAdd={false} asset="ETH" />
                </div>
              </div>
              <hr className="my-4 h-[1px] border-t-0 bg-grey-500 w-full" />
              <div className="flex justify-between">
                <div className="flex flex-col gap-1">
                  <p className="text-grey-100 text-sm font-normal">Trading Balance</p>
                  <div className="flex gap-1">
                    <Image src="/images/pairs/usdc.svg" alt="usdc" width={20} height={20} />
                    <p className="font-fono text-grey-025 text-base font-normal">
                      {currentWalletBalance?.usdc.formatted} USDC
                    </p>
                  </div>
                </div>

                <div className="flex gap-2">
                  <ManageFunds isAdd={true} asset="USDC" />
                  <ManageFunds isAdd={false} asset="USDC" />
                </div>
              </div>
              <hr className="my-4 h-[1px] border-t-0 bg-grey-500 w-full" />
              <Button variant="oneCT" className="w-full" onClick={() => {
            if (privateKey) {
              copy(privateKey);
              privateKeyCopied();
            }
          }}>
                Copy Private Key
              </Button>
            </div>
          </>}
      </div>
    </div>;
};
export default OneCTSection;