import { ReactNode } from 'react';
export const enum AnnouncementType {
  MARQUEE = 'MARQUEE',
  STILL = 'STILL',
}
type Announcement = {
  type: AnnouncementType.STILL;
  label: string;
  id: string;
  text: ReactNode;
  redirectLabel: string;
  redirectUri: string;
  sameSite: boolean;
} | {
  type: AnnouncementType.MARQUEE;
  label: string;
  id: string;
  text: ReactNode;
  value: {
    id: number;
    assetUri: string;
    base: string;
    quote: string;
    desc: ReactNode;
  }[];
};
export const announcements: Announcement = {
  type: AnnouncementType.STILL,
  label: 'Reduced Fees',
  id: '0x2d07214ea9023',
  text: <p className="text-grey-050 text-sm font-normal">
      Avantis v1.25 is live, making Avantis the most competitive exchange for cross-asset leverage
    </p>,
  redirectLabel: 'Read more here',
  redirectUri: 'https://avantisfi.medium.com/avantis-v1-25-competitive-fee-overhaul-c3c9015a53b9',
  // to be added
  sameSite: false
};