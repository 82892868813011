import { useCallback, useMemo } from 'react';
import { useAccount, useConnect } from 'wagmi';
import { useCapabilities } from 'wagmi/experimental';

// List of connector IDs known to support capabilities
const CAPABILITY_SUPPORTED_CONNECTORS = ['coinbase', 'safe', 'coinbaseWalletSDK'];

export const useSmartWallet = () => {
  const { connectors, connect } = useConnect();
  const { address, chainId, connector } = useAccount();

  // Check if the connector is likely to support capabilities
  const supportsCapabilities = useMemo(() => {
    return connector && CAPABILITY_SUPPORTED_CONNECTORS.includes(connector.id);
  }, [connector]);

  const { data: availableCapabilities } = useCapabilities({
    account: address,
    query: {
      retry: false,
      retryOnMount: false,
      enabled: !!address && supportsCapabilities, // Only run the query if address exists and capabilities are likely supported
    },
  });

  const capabilities = useMemo(() => {
    if (!availableCapabilities || !chainId) return {};
    const capabilitiesForChain = availableCapabilities[chainId];
    if (!capabilitiesForChain) return {};
    if (capabilitiesForChain.paymasterService && capabilitiesForChain.paymasterService.supported) {
      return {
        paymasterService: {
          url: `${document.location.origin}/api/paymaster`,
        },
      };
    }
    return {};
  }, [availableCapabilities, chainId]);

  const createSmartWallet = useCallback(() => {
    const coinbaseWalletConnector = connectors.find(
      (connector) => connector.id === 'coinbaseWalletSDK'
    );
    if (coinbaseWalletConnector) {
      connect({ connector: coinbaseWalletConnector });
    }
  }, [connectors, connect]);

  return {
    createSmartWallet,
    isSmartWallet: !!Object.keys(capabilities).length,
    capabilities,
    availableCapabilities,
  };
};

export default useSmartWallet;
