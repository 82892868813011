import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

export enum ToastPosition {
  TOP_RIGHT = 'top-right',
  TOP_LEFT = 'top-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
  TOP_CENTER = 'top-center',
  BOTTOM_CENTER = 'bottom-center',
}

type State = {
  showBanner: boolean;
  skipOpenTradeConfirmations: boolean;
  skipCloseTradeConfirmations: boolean;
  showTradeRightPanel: boolean;
  showRecentTradesAndNews: boolean;
  toastPosition: ToastPosition;
  showGrossPnl: boolean;
  closedBanners: {
    [id: string]: {
      closeCount: number;
      lastClosedAt: number;
    };
  };
};

type Action = {
  updateShowBanner: (value: State['showBanner']) => void;
  updateOpenTradeConfirmations: (value: State['skipOpenTradeConfirmations']) => void;
  updateCloseTradeConfirmations: (value: State['skipCloseTradeConfirmations']) => void;
  updateShowTradeRightPanel: (value: State['showTradeRightPanel']) => void;
  updateShowRecentTradesAndNews: (value: State['showRecentTradesAndNews']) => void;
  updateShowGrossPnl: (value: State['showGrossPnl']) => void;
  updateToastPosition: (value: ToastPosition) => void;
  closeBanner: (id: string) => void;
  shouldShowBanner: (id: string) => boolean;
  reset: () => void;
};

type MyPersist = (
  config: StateCreator<State & Action>,
  options: PersistOptions<State & Action>
) => StateCreator<State & Action>;

const initialState = {
  showBanner: false,
  skipOpenTradeConfirmations: false,
  skipCloseTradeConfirmations: false,
  lastAnnouncemnetId: '',
  showTradeRightPanel: false,
  showRecentTradesAndNews: true,
  toastPosition: ToastPosition.TOP_LEFT,
  showGrossPnl: true,
  closedBanners: {},
};

export const useUIStore = create<State & Action>(
  (persist as MyPersist)(
    (set, get) => ({
      ...initialState,
      updateShowBanner: (value) => set(() => ({ showBanner: value })),
      updateOpenTradeConfirmations: (value) => set(() => ({ skipOpenTradeConfirmations: value })),
      updateCloseTradeConfirmations: (value) => set(() => ({ skipCloseTradeConfirmations: value })),
      updateShowTradeRightPanel: (value) => set(() => ({ showTradeRightPanel: value })),
      updateShowRecentTradesAndNews: (value) => set(() => ({ showRecentTradesAndNews: value })),
      updateShowGrossPnl: (value) => set(() => ({ showGrossPnl: value })),
      updateToastPosition: (value: ToastPosition) => set(() => ({ toastPosition: value })),
      closeBanner: (id: string) => {
        const currentBanner = get().closedBanners[id] || { closeCount: 0, lastClosedAt: 0 };
        const newCloseCount = currentBanner.closeCount + 1;

        set((state) => ({
          closedBanners: {
            ...state.closedBanners,
            [id]: {
              closeCount: newCloseCount,
              lastClosedAt: Date.now(),
            },
          },
        }));
      },
      shouldShowBanner: (id: string) => {
        const banner = get().closedBanners[id];
        if (!banner) return true;

        const { closeCount, lastClosedAt } = banner;
        const oneDayInMs = 24 * 60 * 60 * 1000;

        if (closeCount === 1) {
          return Date.now() - lastClosedAt > oneDayInMs;
        } else if (closeCount >= 2) {
          return false;
        }
        return true;
      },
      reset: () => set(() => ({ ...initialState })),
    }),
    {
      name: 'avantis-user-settings',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
