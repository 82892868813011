import BigNumber from 'bignumber.js';
import { IAsset } from './ManageFunds';
import { getPrecision } from '@/lib/utils';
const ButtonCTA = ({
  isAdd,
  amount,
  asset
}: {
  isAdd: boolean;
  amount: string;
  asset: IAsset;
}) => {
  const amountBigNumber = BigNumber(amount);
  const precision = getPrecision(amountBigNumber);
  if (isAdd) {
    return `Deposit ${amountBigNumber.toFixed(precision)} ${asset}`;
  } else {
    return `Withdraw ${amountBigNumber.toFixed(precision)} ${asset}`;
  }
};
export default ButtonCTA;