import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useEffect } from 'react';
const PinFooter = ({
  disabled,
  cta,
  action
}: {
  disabled: boolean;
  cta: string;
  action: () => void;
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !disabled) {
        action();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [action, disabled]);
  return <div className="flex gap-2" data-sentry-component="PinFooter" data-sentry-source-file="PinFooter.tsx">
      <Button className={cn('w-full', disabled ? 'grayscale' : '')} variant={'long'} onClick={() => action()} disabled={disabled} data-sentry-element="Button" data-sentry-source-file="PinFooter.tsx" data-sentry-element="Button">
        {cta}
      </Button>
    </div>;
};
export default PinFooter;