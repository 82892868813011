'use client';

import { useVersionChecker } from '@/hooks/shared/useVersionChecker';
import { useState, useEffect } from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';
import { Button } from '../ui/button';
import { IoReload } from 'react-icons/io5';
export function VersionChecker() {
  const newVersionAvailable = useVersionChecker();
  const [timeLeft, setTimeLeft] = useState(60);
  useEffect(() => {
    if (newVersionAvailable && timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (newVersionAvailable && timeLeft === 0) {
      window.location.reload();
    }
  }, [newVersionAvailable, timeLeft]);
  if (!newVersionAvailable) return null;
  return <Alert className="fixed bottom-16 md:bottom-8 right-0 m-4 w-fit z-[999]" data-sentry-element="Alert" data-sentry-component="VersionChecker" data-sentry-source-file="VersionChecker.tsx">
      <AlertCircle className="h-4 w-4" data-sentry-element="AlertCircle" data-sentry-source-file="VersionChecker.tsx" data-sentry-element="AlertCircle" />
      <AlertTitle data-sentry-element="AlertTitle" data-sentry-source-file="VersionChecker.tsx" data-sentry-element="AlertTitle">Heads up!</AlertTitle>
      <AlertDescription className="text-grey-100" data-sentry-element="AlertDescription" data-sentry-source-file="VersionChecker.tsx" data-sentry-element="AlertDescription">
        <p>
          A new version of the app is available. Page will reload in{' '}
          <span className="text-white font-medium">{timeLeft}</span> seconds.
        </p>
        <Button className="mt-2 text-sm gap-2" onClick={() => window.location.reload()} data-sentry-element="Button" data-sentry-source-file="VersionChecker.tsx" data-sentry-element="Button">
          <IoReload size={18} data-sentry-element="IoReload" data-sentry-source-file="VersionChecker.tsx" data-sentry-element="IoReload" />
          Reload Now
        </Button>
      </AlertDescription>
    </Alert>;
}