import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import ResponsiveDialog from '../ResponsiveDialog';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useAccount } from 'wagmi';
import { fetchUserProfileNonce, updateUserProfile } from '@/services/trade/api';
import { signMessage } from '@wagmi/core';
import { config } from '@/blockchain/configs/wagmi';
import useAppToast from '@/hooks/shared/useAppToast';
import { serializeError } from '@/lib/transaction';
import Spinner from '../Spinner';
import useLeaguesUserInfo from '@/hooks/leagues/useLeagueUserInfo';
import useUserProfile from '@/hooks/leagues/useUserProfile';
const EditUsername = ({
  openModal,
  setOpenModal
}: {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    failed,
    success
  } = useAppToast();
  const {
    address
  } = useAccount();
  const {
    userProfileData,
    refetchUserProfile
  } = useUserProfile();
  const {
    refetchLeaguesUserInfo
  } = useLeaguesUserInfo();
  const [username, setUsername] = useState<string>('');
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    if (userProfileData) {
      setUsername(userProfileData.username);
    }
  }, [userProfileData]);
  const updateProfileUserName = async () => {
    if (userProfileData && address) {
      try {
        setProcessing(true);
        const nonce = await fetchUserProfileNonce({
          address
        });
        const messageToSign = `Update Profile:\nWallet Address: ${address}\nUsername: ${username}\nProfile Picture: ${userProfileData.profileImageKey}\nNonce: ${nonce}\n\nBy signing this message, you authorize the update of your profile details.`;
        const signature = await signMessage(config, {
          message: messageToSign
        });
        await updateUserProfile({
          address,
          username,
          imageKey: userProfileData.profileImageKey,
          signature
        });
        success({
          message: 'Your user name has been updated!',
          heading: 'Username updated'
        });
        setProcessing(false);
        refetchLeaguesUserInfo();
        refetchUserProfile();
        setOpenModal(false);
      } catch (error) {
        console.log(error);
        const e = serializeError(error);
        failed({
          error: e.message
        });
        setProcessing(false);
      }
    }
  };
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    if (e.target.value.length > 20 || userProfileData && e.target.value == userProfileData.username) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  };
  return <ResponsiveDialog heading={<p className="text-grey-000 text-lg font-medium">Edit Username</p>} open={openModal} className="max-w-[480px]" onCleanup={() => {
    setOpenModal(false);
  }} footer={<div className="flex gap-2">
          <Button className="w-full text-base font-normal" variant="collateral" onClick={() => {
      setOpenModal(false);
    }}>
            Cancel
          </Button>
          <Button className="w-full" variant="long" disabled={disabled || processing} onClick={updateProfileUserName}>
            {processing ? <Spinner /> : <p>Save</p>}
          </Button>
        </div>} data-sentry-element="ResponsiveDialog" data-sentry-component="EditUsername" data-sentry-source-file="EditUsername.tsx">
      <div className="w-full px-8 py-6 flex flex-col items-center gap-6">
        <p className="text-grey-100 text-base font-normal">Enter a new username</p>

        <div className="border-grey-400 border bg-grey-600 flex justify-between rounded-lg h-full">
          <div className="py-2 px-3">
            <Input className="bg-grey-600 border-0 ring-0 focus-visible:ring-0 focus-visible:ring-offset-0 h-6 p-0 text-grey-000 font-medium text-base w-52" value={username} onChange={handleInput} data-sentry-element="Input" data-sentry-source-file="EditUsername.tsx" data-sentry-element="Input" />
          </div>
        </div>
      </div>
    </ResponsiveDialog>;
};
export default EditUsername;