import { Button } from '@/components/ui/button';
import { Minus, Plus } from 'lucide-react';
import { Dispatch, SetStateAction } from 'react';
const AdjustBalanceButtons = ({
  isAdd,
  setOpenManageFunds
}: {
  isAdd: boolean;
  setOpenManageFunds: Dispatch<SetStateAction<boolean>>;
}) => {
  return <Button variant="actions" className="p-2 min-w-[38px] min-h-[38px]" onClick={() => {
    setOpenManageFunds(true);
  }} data-sentry-element="Button" data-sentry-component="AdjustBalanceButtons" data-sentry-source-file="AdjustBalanceButtons.tsx">
      {isAdd ? <Plus color="#fff" strokeWidth={3} height={24} width={24} /> : <Minus color="#fff" strokeWidth={3} height={24} width={24} />}
    </Button>;
};
export default AdjustBalanceButtons;