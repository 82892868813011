import { useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';
import { requestApp } from '@/lib/api';

export type TTotalVolumeData = {
  data: Array<{
    _id: number | null;
    total: number;
    totalCollateral: number;
  }>;
  success: boolean;
};

export type TWinRateData = {
  winRate: number;
  success: boolean;
};

export type TProfitLossData = {
  data: Array<{
    total: number;
    totalCollateral: number;
    pairIndex: null;
  }>;
  avg: number;
  totalCount: number;
  success: boolean;
};

export type TTotalFeesData = {
  totalFees: number;
  success: boolean;
};

export type TLossProtectionData = {
  lossProtection: number;
  success: boolean;
};

const usePortfolioStats = () => {
  const { address } = useAccount();

  const { data: totalVolumeData, isLoading: totalVolumeIsLoading } = useQuery({
    queryKey: ['volume-size-data', address],
    queryFn: async () => {
      const res = await requestApp<TTotalVolumeData>({
        endpoint: `/history/portfolio/total-size/${address}`,
      });
      return res;
    },
    enabled: !!address,
  });

  const { data: winRateData, isLoading: winRateIsLoading } = useQuery({
    queryKey: ['win-rate-data', address],
    queryFn: async () => {
      const res = await requestApp<TWinRateData>({
        endpoint: `/history/portfolio/win-rate/${address}`,
      });
      return res;
    },
    enabled: !!address,
  });

  const { data: profitLossData, isLoading: profitLossIsLoading } = useQuery({
    queryKey: ['profit-loss-Data', address],
    queryFn: async () => {
      const res = await requestApp<TProfitLossData>({
        endpoint: `/history/portfolio/profit-loss/${address}`,
      });
      return res;
    },
    enabled: !!address,
  });

  const { data: totalFeesData, isLoading: totalFeesIsLoading } = useQuery({
    queryKey: ['total-fees-Data', address],
    queryFn: async () => {
      const res = await requestApp<TTotalFeesData>({
        endpoint: `/history/portfolio/total-fees/${address}`,
      });
      return res;
    },
    enabled: !!address,
  });

  const { data: lossProtectionData, isLoading: lossProtectionIsLoading } = useQuery({
    queryKey: ['loss-protection-Data', address],
    queryFn: async () => {
      const res = await requestApp<TLossProtectionData>({
        endpoint: `/history/portfolio/loss-protection/${address}`,
      });
      return res;
    },
    enabled: !!address,
  });

  return {
    totalVolumeData: {
      data: totalVolumeData,
      isLoading: totalVolumeIsLoading,
    },
    winRateData: {
      data: winRateData,
      isLoading: winRateIsLoading,
    },
    profitLossData: {
      data: profitLossData,
      isLoading: profitLossIsLoading,
    },
    totalFeesData: {
      data: totalFeesData,
      isLoading: totalFeesIsLoading,
    },
    lossProtectionData: {
      data: lossProtectionData,
      isLoading: lossProtectionIsLoading,
    },
  };
};

export default usePortfolioStats;
