import useOneCT from '@/hooks/shared/useOneCT';
import ResponsiveDialog from '../ResponsiveDialog';
import PinFooter from './PinFooter';
import PinModalContent from './PinModalContent';
import { Dispatch, SetStateAction, useState } from 'react';
import { useOneCTStore } from '@/store/oneCT';
const PinModal = ({
  openPinModal,
  setOpenPinModal
}: {
  openPinModal: boolean;
  setOpenPinModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    handlePinEntered
  } = useOneCT();
  const {
    pinError
  } = useOneCTStore(state => state);
  const [pin, setPin] = useState<string>('');
  return <ResponsiveDialog open={openPinModal} heading={'Re-Enable 1CT'} footer={<PinFooter disabled={pin.length < 4 || !pin} cta="Re-Enable" action={async () => {
    try {
      await handlePinEntered({
        pin,
        onSuccessCallback: () => {
          setOpenPinModal(false);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }} />} onCleanup={() => setOpenPinModal(false)} data-sentry-element="ResponsiveDialog" data-sentry-component="PinModal" data-sentry-source-file="PinModal.tsx">
      <PinModalContent setPin={setPin} pin={pin} pinError={pinError} data-sentry-element="PinModalContent" data-sentry-source-file="PinModal.tsx" data-sentry-element="PinModalContent" />
    </ResponsiveDialog>;
};
export default PinModal;