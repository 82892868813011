import memoize from 'lodash/memoize';
import { formatNumber } from './utils';
import { BadgeData } from '@/hooks/xp/useUserPoints';

export type DefaultBadge = {
  badge: string;
  badgeType: 'Trading' | 'Liquidity';
  badgeInfo: {
    streakLength?: number;
    cumulativeVolume?: number;
  };
  status: 'active' | 'inactive';
  boostValue: number;
  badgeLevel: number;
};

export const DEFAULT_BADGES: DefaultBadge[] = [
  {
    badge: 'DegenStreak',
    badgeType: 'Trading',
    badgeInfo: { streakLength: 0 },
    status: 'inactive',
    boostValue: 0,
    badgeLevel: 1,
  },
  {
    badge: 'HigherRoller',
    badgeType: 'Trading',
    badgeInfo: { cumulativeVolume: 0 },
    status: 'inactive',
    boostValue: 0,
    badgeLevel: 1,
  },
  {
    badge: 'SocialButterfly',
    badgeType: 'Trading',
    badgeInfo: { cumulativeVolume: 0 },
    status: 'inactive',
    boostValue: 0,
    badgeLevel: 1,
  },
  {
    badge: 'LockedBoost',
    badgeType: 'Liquidity',
    badgeInfo: { cumulativeVolume: 0 },
    status: 'inactive',
    boostValue: 1,
    badgeLevel: 1,
  },
];

export const xpBlackListed = ['MarketMixologist', 'Testnet OG'];

export function calculateUserPercentile(rank = 0, totalUsers = 0) {
  let percent_rank = 0;
  if (!isNaN(Number(rank)) && !isNaN(Number(totalUsers))) {
    if (totalUsers > 0) {
      const percentile = ((totalUsers - rank) / totalUsers) * 100;
      percent_rank = 100 - percentile;
    }
  }

  if (percent_rank === 0) return 'New';

  if (percent_rank < 0.5) {
    return 'Top 0.5%';
  } else if (percent_rank < 1) {
    return 'Top 1%';
  } else if (percent_rank < 5) {
    return 'Top 5%';
  } else if (percent_rank < 20) {
    return 'Top 20%';
  } else if (percent_rank < 50) {
    return 'Top 50%';
  }
  return `${Math.round(percent_rank)}%`;
}

export const mergeBadges = memoize(
  (badgeData: BadgeData[], defaultBadges: DefaultBadge[] = DEFAULT_BADGES) => {
    const existingBadgeNames = new Set(badgeData.map((badge) => badge.badge));
    const newBadges = defaultBadges.filter((badge) => !existingBadgeNames.has(badge.badge));

    const badges = [...badgeData, ...newBadges].filter(
      (b: any) => !xpBlackListed.includes(b.badge)
    ); // removing blackListed badges
    const lockedBoostIndex = badges.findIndex((b: any) => b.badge === 'LockedBoost');
    if (lockedBoostIndex !== -1) {
      const [lockedBoost] = badges.splice(lockedBoostIndex, 1);
      badges.unshift(lockedBoost);
    }

    return badges;
  }
);

export const degenStreakCallback = (
  badgeInfo: { lastTradeDate: string; streakLength: number },
  totalStreakLength = 30
) => {
  const currentStreak = badgeInfo?.streakLength ?? 0;
  const progress = (currentStreak / totalStreakLength) * 100;

  return {
    progress: progress > 100 ? `${100}%` : `${progress}%`,
    currentVolume: currentStreak + ' days',
    totalVolume: `${totalStreakLength} days`,
  };
};

export const cumulativeVolumeCallbacks = (
  badgeInfo: { cumulativeVolume: number },
  targetVolume = 5_000_000
) => {
  const cumulativeVolume = badgeInfo?.cumulativeVolume ?? 0;

  const progress = (cumulativeVolume / targetVolume) * 100;
  return {
    progress: progress > 100 ? `${100}%` : `${progress}%`,
    currentVolume: formatNumber(cumulativeVolume),
    totalVolume: formatNumber(targetVolume),
  };
};

export const lockedBoostCallback = (badgeInfo: { boostValue: number }) => {
  const boostValue = badgeInfo?.boostValue ?? 1;
  const targetVolume = 5;
  const progress = (boostValue / targetVolume) * 100;

  return {
    progress: progress > 100 ? `${100}%` : `${progress}%`,
    currentVolume: formatNumber(boostValue),
    totalVolume: `${formatNumber(targetVolume)}x`,
  };
};

export const marketMixologistCallback = (
  badgeInfo: { pairIndexes: number[] },
  pairs: { pairIndex: number; groupIndex: number }[] = []
) => {
  const pairIndices = badgeInfo?.pairIndexes;

  const assetCategoryTraded: Record<number, number> = {};
  if (pairIndices) {
    pairIndices.forEach((index) => {
      const pair = pairs[index];
      if (pair) {
        assetCategoryTraded[pair.groupIndex] = assetCategoryTraded.hasOwnProperty(pair.groupIndex)
          ? assetCategoryTraded[pair.groupIndex] + 1
          : 1;
      }
    });
  }

  const assetClassesTradedIn = Object.keys(assetCategoryTraded).length;
  const totalAssetClasses = 6;

  const progress = (assetClassesTradedIn / totalAssetClasses) * 100;

  return {
    progress: progress > 100 ? `${100}%` : `${progress}%`,
    currentVolume: assetClassesTradedIn,
    totalVolume: totalAssetClasses,
  };
};

export const lpCallback = () => {
  return {
    progress: '100%',
  };
};
