'use client';

import React, { useState } from 'react';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { requestApp } from '@/lib/api';
import { noOp } from '@/constants/common';
import axios from 'axios';
import { Checkbox } from '../ui/checkbox';
import Link from 'next/link';
import { LogOut } from 'lucide-react';
import { captureError } from '@/lib/sentry';
type SignatureCheckResponse = {
  inTnc: boolean;
  success: boolean;
};
type NonceResponse = {
  nonce: string;
  success: boolean;
};
type SanctionsResponse = {
  isSanctioned: boolean;
  success: boolean;
};
type SignatureSubmitResponse = {
  success: boolean;
};
export default function TNCCheck() {
  const {
    address,
    isConnected
  } = useAccount();
  const queryClient = useQueryClient();
  const {
    disconnect
  } = useDisconnect();
  const [isChecked, setIsChecked] = useState(false);
  const {
    signMessageAsync
  } = useSignMessage();
  const {
    data: signatureData
  } = useQuery({
    queryKey: ['signature', address],
    queryFn: async () => {
      const response = await requestApp<SignatureCheckResponse>({
        endpoint: `/tnc/user/status/${address}`
      });
      return response;
    },
    enabled: !!address
  });
  const {
    data: sanctionsData
  } = useQuery({
    queryKey: ['sanctions', address],
    queryFn: async () => {
      const response = await axios.get<SanctionsResponse>(`/api/sanctions?address=${address}`);
      return response.data;
    },
    enabled: !!address
  });
  const mutation = useMutation({
    mutationFn: async (signature: string) => {
      const response = await requestApp<SignatureSubmitResponse>({
        endpoint: `/tnc/user/join/${address}`,
        method: 'POST',
        data: {
          signature
        }
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['signature', address]
      });
    }
  });
  const handleSign = async (): Promise<void> => {
    if (!address) return;
    try {
      const {
        nonce
      } = await requestApp<NonceResponse>({
        endpoint: `/tnc/user/nonce/${address}`
      });
      const message = `Welcome to Avantis!\n\nClick to sign in and accept the Avantis Terms of Service (https://www.avantisfi.com/docs/tos).\n\nI confirm that I am not a resident of any of the following countries/regions: Belarus, Cuba, Iran, North Korea, Russia, Syria, Canada, Crimea, United Kingdom, United States of America.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address:\n${address}\n\nNonce:\n${nonce}`;
      const signature = await signMessageAsync({
        message
      });
      await mutation.mutateAsync(signature);
    } catch (error) {
      captureError(error, {
        address
      });
      console.error('Error signing message:', error);
    }
  };
  if (isConnected && sanctionsData && sanctionsData.isSanctioned) {
    return <Dialog open={isConnected && sanctionsData.isSanctioned} onOpenChange={noOp}>
        <DialogContent className="p-8 focus-visible:ring-0">
          <DialogHeader>
            <DialogTitle>Sanctioned!</DialogTitle>
            <DialogDescription>
              Your wallet is associated with a sanctioned activity.
            </DialogDescription>
          </DialogHeader>
          <p className="py-4">
            You are not allowed to use this app as your wallet is associated with a sanctioned
            activity.
          </p>
          <Button className="bg-[#FF4949]" variant="short" onClick={() => disconnect()}>
            Disconnect
          </Button>
        </DialogContent>
      </Dialog>;
  }
  if (isConnected && signatureData && !signatureData?.inTnc) {
    return <Dialog open={isConnected && !signatureData?.inTnc} onOpenChange={noOp}>
        <DialogContent className="p-8 focus-visible:ring-0">
          <DialogHeader>
            <DialogTitle>Terms of Service</DialogTitle>
            <DialogDescription>
              Please sign the Terms of Service to continue using the app.
            </DialogDescription>
          </DialogHeader>
          <div className="flex items-center space-x-2 py-4">
            <Checkbox className="focus-visible:ring-0" id="terms" checked={isChecked} onCheckedChange={() => setIsChecked(!isChecked)} />
            <label htmlFor="terms" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              I agree to the{' '}
              <Link className="underline" href="/docs/tos" target="_blank">
                Terms of Service
              </Link>
            </label>
          </div>
          <div className="flex items-center justify-between gap-4">
            <Button className="focus-visible:ring-0 flex-1" variant="long" onClick={handleSign} disabled={mutation.isPending || !isChecked}>
              {mutation.isPending ? 'Signing...' : 'Sign Message'}
            </Button>
            <Button className="bg-[#FF4949]" variant="short" onClick={() => disconnect()}>
              <LogOut className="w-4 h-4" />
            </Button>
          </div>
        </DialogContent>
      </Dialog>;
  }
  return null;
}