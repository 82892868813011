import { useQuery } from '@tanstack/react-query';

import { erc20Abi } from 'viem';
import { useAccount, usePublicClient, useReadContracts } from 'wagmi';

import { contractsConfig } from '@/blockchain/contracts';
import { useMemo } from 'react';
import useOneCT from './useOneCT';
import { useOneCTStore } from '@/store/oneCT';
import BigNumber from 'bignumber.js';

export type Assets = {
  usdc: {
    raw: bigint;
    formatted: string;
  };
  eth: {
    raw: bigint;
    formatted: string;
  };
};
type Indexable = {
  [x: string]: Assets;
};

const useWalletBalances = () => {
  const client = usePublicClient();
  const { address } = useAccount();
  const { oneCTWallets } = useOneCT();
  const oneCTWalletList = useMemo(() => {
    if (!oneCTWallets) return [];
    return oneCTWallets.map((wallet) => wallet.address);
  }, [oneCTWallets]);

  const { publicKey } = useOneCTStore((state) => state);

  const walletList = useMemo(
    () => (address ? [address, ...oneCTWalletList] : []),
    [address, oneCTWalletList]
  );

  const {
    data: oneCtUSDCWalletBalances,
    isLoading: isFetchingUSDCBalance,
    refetch: refetchUSDCBalances,
  } = useReadContracts({
    contracts: walletList.map((wallet) => ({
      address: contractsConfig.USDC.address,
      abi: erc20Abi,
      functionName: 'balanceOf',
      args: [wallet],
    })),
    query: {
      enabled: !!address,
      refetchInterval: 10_000,
    },
  });

  const {
    data: oneCtETHWalletBalances,
    isLoading: isFetchingETHBalance,
    refetch: refetchETHBalances,
  } = useQuery<bigint[]>({
    queryKey: ['one-ct-eth-balance', address, walletList],
    queryFn: async () => {
      const response = await Promise.all(
        walletList.map((wallet) => client.getBalance({ address: wallet }) as Promise<bigint>)
      );

      return response;
    },
    enabled: !!address,
    refetchInterval: 5_000,
  });

  return useMemo(() => {
    const walletBalanceByAddress: Indexable = walletList.reduce((acc, curr, index) => {
      return {
        ...acc,
        [curr]: {
          usdc: {
            raw: oneCtUSDCWalletBalances?.[index].result || 0n,
            formatted: BigNumber(oneCtUSDCWalletBalances?.[index]?.result?.toString() || 0)
              .dividedBy(1e6)
              .toFixed(2, BigNumber.ROUND_FLOOR),
          },
          eth: {
            raw: oneCtETHWalletBalances?.[index] || 0n,
            formatted: BigNumber(oneCtETHWalletBalances?.[index]?.toString() || 0)
              .dividedBy(1e18)
              .toFixed(6, BigNumber.ROUND_FLOOR),
          },
        },
      };
    }, {});

    let currentWalletBalance;
    let currentWallet;
    if (publicKey) {
      currentWalletBalance = walletBalanceByAddress[publicKey];
      currentWallet = publicKey;
    } else if (address) {
      currentWalletBalance = walletBalanceByAddress[address];
      currentWallet = address;
    } else {
      currentWalletBalance = null;
      currentWallet = null;
    }

    return {
      currentWallet,
      walletBalanceByAddress,
      currentWalletBalance,
      isLoading: isFetchingETHBalance || isFetchingUSDCBalance,
      refetch: () => {
        refetchUSDCBalances();
        refetchETHBalances();
      },
    };
  }, [
    oneCtETHWalletBalances,
    oneCtUSDCWalletBalances,
    isFetchingETHBalance,
    isFetchingUSDCBalance,
    address,
    publicKey,
    refetchUSDCBalances,
    refetchETHBalances,
  ]);
};

export default useWalletBalances;
