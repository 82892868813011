'use client';

import Image from 'next/image';
import Marquee from 'react-fast-marquee';
import { useUIStore } from '@/store/ui';
import { announcements, AnnouncementType } from '@/constants/announcements';
import { X } from 'lucide-react';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';
export default function Announcement() {
  const closeBanner = useUIStore(state => state.closeBanner);
  const closedBanners = useUIStore(state => state.closedBanners);
  const shouldShowBanner = useUIStore(state => state.shouldShowBanner);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(shouldShowBanner(announcements.id));
  }, [closedBanners]);
  useInterval(() => {
    setIsVisible(shouldShowBanner(announcements.id));
  }, 300_000);
  if (!isVisible) {
    return null;
  }
  return <>
      <div className="relative z-[21] flex items-center bg-[#181a20] w-full px-5 py-2 gap-5 border-grey-500 box-border">
        {announcements.type === AnnouncementType.MARQUEE && <div className="flex items-center justify-between w-full gap-2 md:gap-6">
            <div className="flex items-center bg-[#bdf556] text-[#101115] px-3 py-1 rounded-full gap-2 min-w-fit">
              <Image src={`/images/potential.svg`} width={15} height={15} alt="new_assets" />
              <span className="font-primary text-xs md:text-sm font-medium leading-5 whitespace-nowrap">
                {announcements.label}
              </span>
            </div>

            <Marquee pauseOnClick pauseOnHover autoFill>
              {announcements.value.map(data => <div className="flex justify-center items-center mr-4 gap-4 text-grey-200" key={data.id}>
                  <div className="flex items-center justify-start gap-2">
                    <div className="w-6 h-6 relative">
                      <Image src={data.assetUri} fill alt={data.base} />
                    </div>
                    {data.desc}
                  </div>

                  <div className="w-2 h-2 rounded-full bg-grey-500"></div>
                </div>)}
            </Marquee>
          </div>}

        {announcements.type === AnnouncementType.STILL && <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full gap-2 md:gap-6">
            <div className="flex items-center bg-[#bdf556] text-[#101115] px-3 py-1 rounded-full gap-2 min-w-fit">
              <Image src={`/images/potential.svg`} width={15} height={15} alt="new_assets" />
              <span className="font-primary text-xs md:text-sm font-medium leading-5 whitespace-nowrap">
                {announcements.label}
              </span>
            </div>

            <div className="w-full overflow-hidden text-grey-200 text-sm md:text-base">
              {announcements.text}
            </div>
            <div className="whitespace-nowrap cursor-pointer mr-10">
              {announcements.sameSite ? <Link className="text-[#96e21b] font-primary text-xs md:text-sm font-medium leading-8 tracking-wide underline" href={announcements.redirectUri}>
                  {announcements.redirectLabel}
                </Link> : <Link className="text-[#96e21b] font-primary text-xs md:text-sm font-medium leading-8 tracking-wide underline" href={announcements.redirectUri} target="_blank">
                  {announcements.redirectLabel}
                </Link>}
            </div>
          </div>}

        <div className="w-7 h-7 cursor-pointer" onClick={() => closeBanner(announcements.id)}>
          <X className="size-7" data-sentry-element="X" data-sentry-source-file="Announcement.tsx" data-sentry-element="X" />
        </div>
      </div>
    </>;
}