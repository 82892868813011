import { cn } from '@/lib/utils';
import { Dispatch, SetStateAction } from 'react';
const TradesCount = ({
  tradeCount,
  setTradeCount,
  handleAmount,
  perTradeEst
}: {
  tradeCount: number;
  setTradeCount: Dispatch<SetStateAction<number>>;
  handleAmount: (ethAmout: string) => void;
  perTradeEst: number;
}) => {
  const tradesCount = [15, 30, 50, 100];
  return <div className="bg-grey-600 col-span-7 rounded-lg flex justify-between items-center mt-2.5" data-sentry-component="TradesCount" data-sentry-source-file="TradesCount.tsx">
      {tradesCount.map((count, idx) => {
      return <div role="button" className={cn('cursor-pointer h-14 py-2 px-2 md:px-4 rounded-md text-grey-100 w-full flex flex-col gap-1 items-center justify-center', count === tradeCount && 'text-grey-000 outline outline-primary-100 bg-primary-800')} key={idx} onClick={() => {
        setTradeCount(count);
        handleAmount((count * perTradeEst).toString());
      }}>
            <p className={cn(count === tradeCount ? 'text-primary-100' : 'text-grey-100', 'font-fono text-xs md:text-sm')}>
              {count} Trades
            </p>

            <p className={cn(count === tradeCount ? 'text-grey-025' : 'text-grey-100', 'font-fono text-xs md:text-sm')}>
              {(perTradeEst * count).toFixed(4)} ETH
            </p>
          </div>;
    })}
    </div>;
};
export default TradesCount;