import { useAccount, useReadContracts } from 'wagmi';
import { contractsConfig } from '@/blockchain/contracts';
import { useMemo } from 'react';
import useOneCT from '../shared/useOneCT';
import { zeroAddress } from 'viem';

export type PendingOrderType = {
  orderId: string;
  wantedPrice: string;
  isOpening: boolean;
  pairIndex: number;
};

const usePendingOrders = () => {
  const { address } = useAccount();
  const { oneCTWallets } = useOneCT();

  const oneCTWalletList = useMemo(() => {
    if (!oneCTWallets) return [];
    return oneCTWallets.map((wallet) => wallet.address);
  }, [oneCTWallets]);

  const walletList = useMemo(() => {
    if (!address && !oneCTWalletList) return [];
    return [address, ...oneCTWalletList];
  }, [address, oneCTWalletList]);

  const {
    data: pendingOrdersRaw,
    isRefetching,
    isLoading,
    refetch,
  } = useReadContracts({
    contracts: walletList.map((wallet) => ({
      address: contractsConfig.Multicall.address,
      abi: contractsConfig.Multicall.abi,
      functionName: 'getPendingOrder',
      args: [wallet],
    })),
    query: {
      enabled: !!address,
      refetchInterval: 2_000,
    },
  });

  const { pendingOrders } = useMemo(() => {
    const pendingOrders = pendingOrdersRaw
      ? pendingOrdersRaw
          .filter(({ result, status }) => {
            // @ts-ignore
            return status === 'success' && result && result?.trade?.trader !== zeroAddress;
          })
          .map(({ result }) => {
            // @ts-ignore
            return result.map((order: any) => ({
              orderId: order.orderId.toString(),
              wantedPrice: order.orderInfo.wantedPrice.toString(),
              isOpening: !!Number(order.orderInfo.wantedPrice),
              pairIndex: order.orderInfo.trade.pairIndex,
            }));
          })
          .flat()
      : [];

    return { pendingOrders };
  }, [pendingOrdersRaw]);

  return {
    pendingOrders,
    isLoading,
    isRefetching,
    refetch,
  };
};

export default usePendingOrders;
