import { mainnetConfig } from '@/blockchain/configs/wagmi';
import { useAccount, useEnsAvatar, useEnsName } from 'wagmi';

const useENS = () => {
  const { address } = useAccount();

  const { data: ensName } = useEnsName({
    address: address,
    chainId: 1,
    config: mainnetConfig,
    query: {
      enabled: !!address,
    },
  });

  const { data: ensAvatar } = useEnsAvatar({
    name: ensName as string,
    chainId: 1,
    config: mainnetConfig,
    query: {
      enabled: !!address && !!ensName,
    },
  });

  return { name: ensName || null, avatar: ensAvatar || null };
};

export default useENS;
