import Image from 'next/image';
import Link from 'next/link';
const Leagues = () => {
  return <Link className="bg-gradient-to-r from-error-200 to-secondary-400 p-px p-py rounded-lg" href="/leagues" data-sentry-element="Link" data-sentry-component="Leagues" data-sentry-source-file="Leagues.tsx">
      <div className="h-8 w-12 xl:h-[43px] xl:w-[118px] bg-grey-1000  hover:bg-gradient-to-b hover:from-[#0E0E1380] hover:to-[#7C20AE80] rounded-lg flex items-center gap-2 px-3 py-1">
        <Image src="/images/leagues/header-button.svg" alt="" height={24} width={24} data-sentry-element="Image" data-sentry-source-file="Leagues.tsx" data-sentry-element="Image" />
        <p className="text-base font-medium text-grey-000 hidden xl:block">Leagues</p>
      </div>
    </Link>;
};
export default Leagues;