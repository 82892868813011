export const timerWhitelist = [0, 1, 2, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];

export const getCurrentTimestamp = () => Math.trunc(Date.now() / 1000);

export const delay = async (ms: number, fn?: () => void | undefined) => {
  await new Promise((resolve) => setTimeout(resolve, ms));
  if (typeof fn === 'function') {
    return fn();
  }
};

export const timestampToDateString = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  } as const;

  return date.toLocaleDateString(undefined, options);
};

export function getStartOfPeriod(period: string) {
  const now = new Date();
  let startOfPeriod;

  switch (period) {
    case 'day':
      // Set the date to the start of the current day
      startOfPeriod = new Date(now);
      startOfPeriod.setDate(now.getDate() - 1);
      break;
    case 'week':
      // Set the date to the most recent Sunday
      startOfPeriod = new Date(now);
      startOfPeriod.setDate(now.getDate() - now.getDay());
      break;

    case 'month':
      // Set the date to the first day of the current month
      startOfPeriod = new Date(now.getFullYear(), now.getMonth(), 1);
      break;

    case 'year':
      // Set the date to the first day of the current year
      startOfPeriod = new Date(now.getFullYear(), 0, 1);
      break;

    default:
      return null;
  }

  // Reset time part to start of the day
  startOfPeriod.setHours(0, 0, 0, 0);

  // Convert to ISO string
  return startOfPeriod.toISOString();
}
