import { Abi, Address, encodePacked, Hash, hexToString, pad, zeroHash } from 'viem';
import useSmartWallet from '../shared/useSmartWallet';
import useSponsoredTransaction from '../shared/useSponsoredTransaction';
import { useOneCTStore } from '@/store/oneCT';
import { usePublicClient } from 'wagmi';
import { serializeError } from '@/lib/transaction';
import useAppToast from '../shared/useAppToast';
import { noOp } from '@/constants/common';
import { useExecutionQueue } from '@/store/executionQueue';
import { useWriteContract } from 'wagmi';
import { useAccount } from 'wagmi';
import { captureError } from '@/lib/sentry';
import useReferral from '../referral/useReferral';
import { useReadContract } from 'wagmi';
import { contractsConfig } from '@/blockchain/contracts';
import { privateKeyToAccount } from 'viem/accounts';
import { writeContract } from '@wagmi/core';
import { config } from '@/blockchain/configs/wagmi';

const useTransaction = () => {
  const { isSmartWallet } = useSmartWallet();
  const { handleSponsoredTransaction } = useSponsoredTransaction();
  const { privateKey, publicKey } = useOneCTStore((state) => state);
  const client = usePublicClient();
  const { address: eoaAddress } = useAccount();
  const { txnInitiate, failed, processing, txnSuccess } = useAppToast();
  const { push } = useExecutionQueue();
  const { writeContractAsync } = useWriteContract();
  const { traderCode } = useReferral();

  const { data: traderCodeResult, refetch: refetchOneCTTraderData } = useReadContract({
    ...contractsConfig.Referral,
    functionName: 'traderReferralCodes',
    args: [publicKey],
    query: {
      enabled: !!publicKey,
    },
  });

  const _oneCTtraderCode = (traderCodeResult as Hash) || '';
  const oneCTtraderCode =
    _oneCTtraderCode === zeroHash ? '' : hexToString(_oneCTtraderCode, { size: 32 });

  const executeTransaction = async (
    {
      address,
      abi,
      functionName,
      args,
      value = 0n,
      txSuccessCallback = noOp,
      txFailedCallback = noOp,
      feedId = null,
    }: {
      address: Address;
      abi: Abi;
      functionName: string;
      args: any[];
      value?: bigint;
      txSuccessCallback?: () => void;
      txFailedCallback?: () => void;
      priceUpdateRequired?: boolean;
      feedId?: string | null;
    },
    forceEOA = false
  ) => {
    let tx: Hash | undefined = undefined;

    try {
      if (isSmartWallet) {
        txnInitiate();
        tx = await handleSponsoredTransaction({
          address,
          abi,
          functionName,
          args,
          value,
        });

        processing();
      } else if (privateKey && !forceEOA) {
        processing();

        // Check for traderCode mismatch and apply if there is
        if (traderCode !== oneCTtraderCode) {
          const account = privateKeyToAccount(privateKey);

          const codeBytes = pad(encodePacked(['string'], [traderCode]), {
            dir: 'right',
          });

          try {
            const tx = await writeContract(config, {
              ...contractsConfig.Referral,
              functionName: 'setTraderReferralCodeByUser',
              args: [codeBytes],
              account,
            });

            client.waitForTransactionReceipt({ hash: tx! }).then((receipt) => {
              if (receipt.status === 'success') {
                refetchOneCTTraderData();
              } else {
                console.log('error setting trader code on 1ct', tx);
              }
            });
          } catch (error) {
            const e = serializeError(error);
            console.log('error setting trader code on 1ct', e.message);
          }
        }

        // since 1CT is entirely managed by us we need to use a queue, not required for regular EOA
        // since wallet manages this

        push({
          tx: {
            address,
            abi,
            functionName,
            args,
            value,
            txSuccessCallback,
            txFailedCallback,
          },
          privateKey,
          feedId,
        });
        return true;
      } else {
        txnInitiate();
        await client.simulateContract({
          address,
          abi,
          functionName,
          args,
          value,
          account: eoaAddress,
        });

        tx = await writeContractAsync({
          address,
          abi,
          functionName,
          args,
          value,
        });

        processing();
      }

      const receipt = await client.waitForTransactionReceipt({ hash: tx! });
      if (receipt.status === 'success') {
        txnSuccess(tx!);
        txSuccessCallback?.();
        return true;
      }
      failed({ error: 'Transaction failed.' });
      txFailedCallback?.();

      return false;
    } catch (e) {
      console.log(e);
      const error = serializeError(e);
      captureError(error, {
        eoaAddress,
        address,
        action: 'executeTransaction',
        tx: { address, abi, functionName, args, value },
      });
      failed({ error: error.message });
      return false;
    }
  };

  return { executeTransaction };
};

export default useTransaction;
