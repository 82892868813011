'use client';

import Image from 'next/image';
import Link from 'next/link';
import NavLinks from './NavLinks';
import MoreDropDown from './MoreDropDown';
import PendingOrders from '@/components/trade/PendingOrders';
import useNetworkSwitch from '@/hooks/shared/useNetworkSwitch';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';
import { Button } from '@/components/ui/button';
import { noOp } from '@/constants/common';
import WalletDetails from './WalletDetails';
import UserPreferences from './UserPreferences';
import { cn } from '@/lib/utils';
import Spinner from '../Spinner';
import AvantisXP from './AvantisXP';
import usePendingOrders from '@/hooks/trade/usePendingOrders';
import Leagues from './Leagues';
export default function Header() {
  const {
    pendingOrders
  } = usePendingOrders();
  const {
    isConnected,
    address,
    isConnecting,
    isReconnecting
  } = useAccount();
  const {
    unsupported,
    switchToBaseChain
  } = useNetworkSwitch();
  const {
    openConnectModal
  } = useConnectModal();
  const isWalletLoading = isConnecting || isReconnecting;
  return <header className="py-3 pl-6 pr-3 sticky top-0 border-grey-500 border-b w-full bg-grey-800 z-50" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <div className="flex justify-between">
        <div className="flex gap-10 items-center">
          <div className="py-1.5">
            <Link className="navbar-brand" href="/" data-sentry-element="Link" data-sentry-source-file="Header.tsx" data-sentry-element="Link">
              <Image className="hidden md:block" priority={true} src="/images/avantis-logo.svg" alt="Avantis" width={118} height={22} data-sentry-element="Image" data-sentry-source-file="Header.tsx" data-sentry-element="Image" />
              <Image className="md:hidden" priority={true} src="/images/avantis-logo-white.svg" alt="Avantis" width={37} height={23} data-sentry-element="Image" data-sentry-source-file="Header.tsx" data-sentry-element="Image" />
            </Link>
          </div>

          <div className="hidden lg:flex gap-2">
            <NavLinks data-sentry-element="NavLinks" data-sentry-source-file="Header.tsx" data-sentry-element="NavLinks" />
            <MoreDropDown data-sentry-element="MoreDropDown" data-sentry-source-file="Header.tsx" data-sentry-element="MoreDropDown" />
          </div>
        </div>
        <div className="flex gap-1.5 justify-between items-center">
          {pendingOrders?.length ? <PendingOrders pendingOrders={pendingOrders} /> : null}
          <Leagues data-sentry-element="Leagues" data-sentry-source-file="Header.tsx" data-sentry-element="Leagues" />
          {isConnected && <AvantisXP />}
          <div className={cn('h-8 md:h-11 px-2 flex items-center md:p-3 rounded bg-grey-600 font-medium', unsupported && 'cursor-pointer')} onClick={unsupported ? switchToBaseChain : noOp}>
            {unsupported ? 'Switch to Base' : <Image src="/images/chains/base.svg" width={20} height={20} alt="base-icon" />}
          </div>
          {isConnected && address ? !unsupported && <WalletDetails /> : !isWalletLoading ? <Button className="h-8 md:h-11" onClick={() => openConnectModal?.()}>
              Login
            </Button> : <div className="p-3 rounded bg-grey-600">
              <Spinner className="size-5" />
            </div>}
          <div className="hidden md:block">
            <UserPreferences data-sentry-element="UserPreferences" data-sentry-source-file="Header.tsx" data-sentry-element="UserPreferences" />
          </div>
        </div>
      </div>
    </header>;
}