import { useState, useRef, useCallback, useEffect } from 'react';

export function useVersionChecker() {
  const workerRef = useRef<Worker | null>(null);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  const initializeWorker = useCallback(() => {
    workerRef.current = new Worker(
      new URL('../../workerScripts/version-checker.worker', import.meta.url),
      {
        type: 'module',
      }
    );
    // Initial check
    workerRef.current.postMessage('CHECK_VERSION');

    workerRef.current.onmessage = (event: MessageEvent) => {
      const { type, version } = event.data;
      if (type === 'VERSION_UPDATE') {
        const currentVersion = localStorage.getItem('appVersion');
        if (currentVersion !== version) {
          if (!!currentVersion) {
            setNewVersionAvailable(true);
          }
          localStorage.setItem('appVersion', event.data.version);
        }
      }
    };

    // Set up interval for periodic checks
    const intervalId = setInterval(() => {
      workerRef.current?.postMessage('CHECK_VERSION');
    }, 60000); // Check every minute

    return () => {
      clearInterval(intervalId);
      workerRef.current?.terminate();
    };
  }, []);

  useEffect(() => {
    initializeWorker();

    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, [initializeWorker]);

  return newVersionAvailable;
}
