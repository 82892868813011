import Image from 'next/image';
import { DefaultRightSide } from '../../InputWithBalance';
import { IAsset } from './ManageFunds';
const InputRightSide = ({
  asset,
  isAdd,
  tradeEst = 0
}: {
  asset: IAsset;
  isAdd: boolean;
  tradeEst?: number;
}) => {
  if (asset === 'ETH' && !isAdd) {
    return <div className="flex min-w-fit gap-2 mr-1 md:mr-3 my-auto">
        <p className="text-base font-normal text-grey-300-disabled-text">ETH</p>
        <Image src="/images/pairs/crypto/ETH.svg" alt="ETH" width={20} height={20} />
      </div>;
  }
  if (asset === 'ETH' && isAdd) {
    return <div className="flex h-full items-start">
        <div className="flex min-w-fit gap-2 mr-3 my-auto">
          <p className="text-base font-normal text-grey-300-disabled-text">ETH</p>
          <Image src="/images/pairs/crypto/ETH.svg" alt="ETH" width={20} height={20} />
        </div>
        <div className="border-l border-grey-400 h-10 font-fono text-sm md:text-base font-normal text-grey-100 w-24 md:w-28 py-2.5 flex items-center justify-center">
          ~{tradeEst} Trades
        </div>
      </div>;
  }
  return <DefaultRightSide data-sentry-element="DefaultRightSide" data-sentry-component="InputRightSide" data-sentry-source-file="InputRightSide.tsx" />;
};
export default InputRightSide;