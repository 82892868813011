import { useCallback } from 'react';
import { walletActionsEip5792 } from 'viem/experimental';
import { usePublicClient, useWalletClient } from 'wagmi';
import { Hash, WalletCallReceipt } from 'viem';
import useSmartWallet from './useSmartWallet';
import { delay } from '@/lib/common';

type SponsoredTxnParams = {
  address: Hash;
  abi: any;
  functionName: string;
  args: any[];
  value?: bigint | false;
};

const useSponsoredTransaction = () => {
  const { data: signer } = useWalletClient();
  const publicClient = usePublicClient();
  const { capabilities, availableCapabilities } = useSmartWallet();

  const handleSponsoredTransaction = useCallback(
    async ({ address, abi, functionName, args, value = false }: SponsoredTxnParams) => {
      if (!availableCapabilities) {
        console.log('sponsored txn not supported on the wallet type');
        return;
      }

      if (!signer) {
        console.log('wallet not connected');
        return;
      }

      const extendedSigner = signer.extend(walletActionsEip5792());

      const id = await extendedSigner.writeContracts({
        contracts: [
          {
            address,
            abi,
            functionName,
            args,
            ...(value ? { value } : {}),
          },
        ],
        capabilities,
      });

      let status = 'PENDING';
      let receipts: WalletCallReceipt<bigint, 'success' | 'reverted'>[] = [];

      while (status === 'PENDING') {
        const callStatus = await extendedSigner.getCallsStatus({
          id,
        });

        status = callStatus.status;
        if (status === 'CONFIRMED' && callStatus.receipts) {
          receipts = callStatus.receipts;
          break;
        }

        await delay(1_000);
      }

      console.log({ status, receipts });

      return receipts[0].transactionHash;
    },
    [availableCapabilities, capabilities, publicClient, signer]
  );

  return { handleSponsoredTransaction, capabilities };
};

export default useSponsoredTransaction;
